<template>
    <v-card class="grey pa-0 ma-0" style="padding: 0px !important; height: 100% !important">
        <v-card-title class="pa-0 ma-0 pa-4 d-flex align-center grey lighten-1 primary--text font-weight-light">
            <div class="d-flex align-center" style="width: 100% !important">
                <div><v-icon large color="primary" class="mr-3">icons8-chat</v-icon>Live Stream Chat</div>
                <div>
                    <v-btn icon @click="scrollToBottom()"><v-icon>icons8-down-button</v-icon></v-btn>
                </div>
                <v-spacer />
                <div>
                    <v-btn icon @click="updateChat(false)"><v-icon>icons8-close</v-icon></v-btn>
                </div>
            </div>
            <div class="oomph-font-body-1">
                <div>Viewers: {{ computedViewerCount }}</div>
            </div>
        </v-card-title>
        <div style="height: calc(100vh - 310px) !important; overflow-y: scroll" class="pa-3" ref="messages">
            <template v-for="msg in chat">
                <div class="d-flex mt-2" :class="msg.msgType === 'presenter' ? 'flex-row-reverse' : 'flex-row'">
                    <v-chip :color="msg.msgType === 'presenter' ? 'primary' : 'white'" style="height: auto; white-space: normal" class="pa-4 mb-2">
                        <div class="d-flex flex-column">
                            <div class="body-2">{{ msg.msgText }}</div>
                            <div class="caption font-weight-bold" :class="msg.msgType === 'presenter' ? 'white--text text--darken-1' : 'grey--text text--darken-1'">
                                <span v-if="msg.msgType === 'presenter'">Presenter: </span><span class="font-weight-medium">{{ msg.userName }} </span>
                            </div>
                            <div class="caption">{{ MIX_formatDateTimeFromNow(msg.timestamp, 'X', false), }}</div>
                        </div>
                    </v-chip>
                </div>
            </template>
        </div>
        <v-card-actions class="pa-0 ma-0 py-2 d-flex align-center grey lighten-1">
            <v-text-field v-on:keyup.enter="sendMsg(msg)" v-model="msg.msgText" dense background-color="white" outlined class="ml-2 primary--text" hide-details="auto"></v-text-field>
            <v-btn @click="sendMsg(msg)" icon class="ml-2" depressed x-large><v-icon class="primary--text">icons8-sent</v-icon></v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    name: 'StreamWeb',
    components: {},
    props: {
        eventdata: Object,
        showchat: Boolean
    },
    data: () => ({
        msg: {
            msgText: '',
            msgType: 'presenter',
            userName: '',
            userId: '1001',
            eventId: '',
            timestamp: ''
        },
        chat: [],
        viewerCount: 0
    }),
    watch: {
        eventdata: {
            handler: function () {
                console.log('Joining Room - ', this.eventdata.id)
                this.$socket.client.emit('joinRoom', this.eventdata.id)
            },
            deep: true
        }
    },
    computed: {
        ...mapGetters({
            GET_FIREBASE_userAuth: 'firebase_auth_store/GET_FIREBASE_userAuth',
            GET_FIREBASE_userAccessToken: 'firebase_auth_store/GET_FIREBASE_userAccessToken'
        }),
        computedViewerCount() {
            let t = this;
            return t.viewerCount;
        },
    },
    methods: {
        updateChat(value) {
            this.$emit('updatechat', value)
        },
        sendMsg(value) {
            this.msg.timestamp = this.$moment().format('X')
            this.msg.eventId = this.eventdata.id
            this.msg.userName = this.GET_FIREBASE_userAccessToken.name
            this.msg.userId = this.GET_FIREBASE_userAccessToken.user_id
            this.$socket.client.emit('serverChat', value)
            this.msg = {
                msgText: '',
                msgType: 'presenter',
                eventId: '',
                userName: '',
                userId: '',
                timestamp: ''
            }
        },
        scrollToBottom() {
            const container = this.$refs.messages
            container.scrollTop = container.scrollHeight
        },
        clickButton(val) {
            this.$socket.client.emit('emit_method', val)
        },
        async getViewerCount() {
            let t = this;
            let result = await t.MIX_getViewerCount(t.eventdata.eventLiveStreamId);
            if (result.code === 1) {
                t.viewerCount = result.data.viewers;
            } else {
                t.viewerCount = 0;
            }
        }
    },
    sockets: {
        connect() {
            console.log('socket connected woohoo')
        },
        clientChat(msg) {
            console.log('receieved chat mesaage from server')
            console.log(JSON.stringify(msg, null, 2))
            this.chat.push({ ...msg })
            this.$nextTick(function () {
                this.scrollToBottom()
            })
        }
    },
    mounted() {
        let t = this;
        t.getViewerCount();
        setInterval(() => {
            let t = this;
            t.getViewerCount();
        }, 10000);
    }
}
</script>
