import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

// * Import modular stores
import firebase_auth_store from "@/plugins/firebase/firebase_auth_store.js";

// Persisted State Required for Maintaining Login State
import createPersistedState from "vuex-persistedstate";

export default new Vuex.Store({
	plugins: [createPersistedState()],
	state: {
		alertBox: { color: "success", timeout: 2000, message: "Success", show: false }, // alertBox state        
		appUpdate: { show: false },
	},
	getters: {
		GET_alertBox(state) { return state.alertBox }, // alertBox getters        
		GET_appUpdate(state) { return state.appUpdate }, // appUpdate getters
		GET_firebase_userAccess(state) { return state.userAccess },
	},
	mutations: {
		MUT_alertBox(state, payload) { state.alertBox = payload }, // alertBox mutation        
		MUT_appUpdate(state, payload) { state.appUpdate = payload }, // appUpdate mutation
		MUT_firebase_userAccess(state, payload) { state.userAccess = payload },
	},
	actions: {
		ACT_alertBox({ commit }, payload) { commit('MUT_alertBox', payload) }, // alertBox action        
		ACT_appUpdate({ commit }, payload) { commit('MUT_appUpdate', payload) }, // appUpdate action		
		ACT_firebase_userAccess({ commit }, payload) { commit('MUT_firebase_userAccess', payload) },
	},
	modules: {
		firebase_auth_store: firebase_auth_store,
	},
});
