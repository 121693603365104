<template>
    <div id="app">
        <v-app>
            <v-app-bar app color="black" v-if="!$route.meta.hideNavbar">
                <div class="d-flex align-center justify-center" style="width: 100% !important">
                    <div v-if="$vuetify.breakpoint.smAndUp" class="text-h6 white--text" style="width: 300px !important;">{{ currentDate }}</div>
                    <v-spacer />
                    <div class=""><v-img max-height="30" :src="require('@/assets/logo.png')" class="greyscale" contain></v-img></div>
                    <v-spacer />
                    <div v-if="$vuetify.breakpoint.smAndUp" class="text-h6 white--text text-right"  style="width: 300px !important;">{{ currentTime }}</div>
                </div>
            </v-app-bar>
            <v-main class="grey darken-4">
                <v-container v-if="$vuetify.breakpoint.smAndUp" fluid fill-height class="d-flex align-center justify-center">
                    <router-view />
                </v-container>
                <v-container v-else fluid fill-height class="d-flex align-center white--text justify-center">
                    <div class="text-h4 font-weight-medium text-center">You Require Desktop or Laptop to be able to use this Platform</div>
                </v-container>
            </v-main>
            <v-bottom-navigation v-model="value" class="primary white--text" height="80" grow  v-if="!$route.meta.hideNavbar">
                <v-btn :disabled="$vuetify.breakpoint.xsOnly" @click="$router.push('/schedule')" value="favorites"  :class="$route.name === 'Schedule' ? 'black':''">
                    <span  class="font-weight-bold white--text">Schedule</span>
                    <v-icon large class="white--text">icons8-calendar</v-icon>
                </v-btn>
                <!-- @click="$router.push('/livestream')" -->
                <v-btn :disabled="$vuetify.breakpoint.xsOnly" value="recent" :class="$route.name === 'LiveStream' ? 'black':''">
                    <span  class="font-weight-bold white--text">Live Stream</span>
                    <v-icon large class="white--text">icons8-live-video-on</v-icon>
                </v-btn>
                <v-btn :disabled="$vuetify.breakpoint.xsOnly" @click="logout()" value="nearby">
                    <span class="font-weight-bold white--text">Log Out</span>
                    <v-icon large class="white--text">icons8-open-door</v-icon>
                </v-btn>
            </v-bottom-navigation>
            <AlertBox />
            <AppUpdate />
        </v-app>
    </div>
</template>

<script>

export default {
    name: "App",


    data: () => ({
        currentDate: null,
        currentTime: null,
        value: "",
        //
    }),
    methods: {
        logout() {
            let t = this;
            t.ACT_FIREBASE_userAuth(null);
            t.$firebase.auth.signOut().then(() => {
                t.$router.push('/').catch((err) => {
                    console.log('Route error: ' + err);
                });
            });
        },
    },
    created() {

        setInterval(() => {
            this.currentDate = this.$moment().format('dddd DD-MMM-YYYY');
            this.currentTime = this.$moment().format("HH:mm:ss");
        }, 1000);
    }
};
</script>
