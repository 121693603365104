<template>
    <div style="width: 100vw !important" class="pa-0">
        <v-row dense fill-height>
            <v-col cols="12" xs="12" :sm="playerCols" class="" style="height: calc(100vh - 165px) !important;">
              <v-card class="elevation-0  grey darken-4 pa-0 ma-0 mr-1" style="padding: 0px !important; height: calc(100vh - 175px) !important;">
                <StreamWeb v-on:updatechat="updateChat" :showchat="showChat" :eventdata="liveStreamEvent" />                
                </v-card>
            </v-col>
            <v-col cols="12" xs="12" class="" :sm="chatCols" >
                <transition name="custom-classes-transition" enter-active-class="animate__animated animate__slideInRight" leave-active-class="animate__animated animate__slideOutRight" mode="out-in">
                    <StreamChat v-on:updatechat="updateChat" :showchat="showChat" v-if="showChat" :eventdata="liveStreamEvent" />
                </transition>
            </v-col>

        </v-row>
    </div>
</template>

<script>
// @ is an alias to /src
import StreamWeb from "@/components/StreamWeb.vue";
import StreamChat from "@/components/StreamChat.vue";
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
    name: "HomeView",
    data: () => ({
      showChat: true,
      playerCols: 9,
      chatCols: 3,
      liveStreamEvent: {},
    }),
    computed: {
    },
    components: {
        StreamWeb,
        StreamChat
    },
    methods: {
      async getLiveStream(id) {
            const liveStreamResult = await this.MIX_redisReadHash('event:'+id);
            // console.log('liveStreamResult', JSON.stringify(liveStreamResult, null, 2));
            this.liveStreamEvent = liveStreamResult.data;
      },
      updateChat(value) {
        this.showChat = value;
        if (value === true) {
          this.playerCols = 9;
          this.chatCols = 3;
        } else {
          setTimeout(() => {
            this.playerCols = 12;
            this.chatCols = 0;
          }, 1000);
        }
      },
       
    },
    created() {
      // console.log('this.$route.params.id = ' + this.$route.params.id);
        this.getLiveStream(this.$route.params.id);
    },
    mounted() {
    },
};
</script>

<style></style>
