import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'

import AlertBox from "@/components/alert/AlertBox.vue";
Vue.component("AlertBox", AlertBox);
//appupdate Component - using service worker
require('./components/appupdate/appupdate_import.js');
// * SERVICE WORKER INTEGRATION
import '@/registerServiceWorker';
import appupdate_mixin from '@/components/appupdate/appupdate_mixin.js';
Vue.use(appupdate_mixin);

// * APP UPDATE COMPONENT
import AppUpdate from '@/components/appupdate/AppUpdate.vue';
Vue.component('AppUpdate', AppUpdate);

// Moment
import moment from 'moment'
Vue.prototype.$moment = moment;

// Axios
import axios from 'axios'
Vue.prototype.$axios = axios;

// Import Icons-8 Library
import "@/assets/css/styles.min.css";

// Import Animate.css
import "animate.css";

import redisMixin from './redis_mixin.js'
Vue.use(redisMixin);

import mixin from './mixin.js'
Vue.use(mixin);
Vue.config.productionTip = false

import VueSocketIOExt from 'vue-socket.io-extended';
import { io } from 'socket.io-client';
// import './registerServiceWorker'
import store from './store'
import { doc, getDoc } from "firebase/firestore";

const socket = io('https://livechat-server.oomphwellness.org');
// const socket = io('http://localhost:8081');
// const socket = io('https://oomph-chatserver-production-ftqyigyrea-ew.a.run.app');
Vue.use(VueSocketIOExt, socket);

//* FIREBASE INTEGRATION
const firebase = require("@/plugins/firebase/firebase-config.js"); // Import Firebase Config for Firebase Object Access
Vue.prototype.$firebase = firebase; // Prototype Firebase for use throughout Project $firebase
import firebase_auth_mixin from "@/plugins/firebase/firebase_auth_mixin.js"; //
Vue.use(firebase_auth_mixin);
import firebase_firestore_mixin from "@/plugins/firebase/firebase_firestore_mixin.js"; //
// import './registerServiceWorker'
Vue.use(firebase_firestore_mixin);

function parseJwt (token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
};


firebase.auth.onAuthStateChanged(async (user) => {
  if (user) {
      await store.dispatch("firebase_auth_store/ACT_FIREBASE_userAuth", user);

  if (user !== null) {
    user.getIdToken(true).then(async (idToken) => {
      var token = parseJwt(idToken)
      await store.dispatch("firebase_auth_store/ACT_FIREBASE_userAccessToken", token);
    })
  }
      await store.dispatch("firebase_auth_store/ACT_FIREBASE_userAuth", user.multiFactor.user);
      const docRef = doc(firebase.db, "users", user.multiFactor.user.uid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
          // await store.dispatch("firebase_auth_store/ACT_FIREBASE_userAuth", docSnap.status);
      }
  }
});

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
