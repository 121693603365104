import { mapActions, mapGetters } from "vuex";

const mixin = {
    data: () => ({
    
    }),
    methods: {
        ...mapActions({
            ACT_alertBox: "ACT_alertBox",
            ACT_puDialog: 'ACT_puDialog'
        }),        
        MIX_formatDateTime(dateTime, formatIn, formatOut) {
            if (dateTime !== "" && dateTime !== null && dateTime !== undefined) {
                return this.$moment(dateTime, formatIn).format(formatOut);
            } else {
                return "";
            }
        },
        MIX_formatDateTimeFromNow(dateTime, formatIn, ago) {
            if (dateTime !== "" && dateTime !== null && dateTime !== undefined) {
                return this.$moment(dateTime, formatIn).fromNow(ago);
            } else {
                return "";
            }
        },
        MIX_alertBox: function (payload) {
            this.ACT_alertBox(payload);
        },     
    }
}

export default {
    install(Vue) {
        Vue.mixin(mixin);
    },
};