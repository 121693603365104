<template>
    <div class="d-flex flex-column">
        <v-row class="pa-4">
            <v-col cols="12" xs="12" sm="12" class="d-flex align-center">
                <div style="width: 20% !important">
                    <template v-if="streaming">
                        <v-btn depressed large class="primary rounded-xl" @click="stopStreaming"> <v-icon class="mr-2">icons8-stop</v-icon>Stop Streaming</v-btn>
                    </template>
                    <template v-else>
                        <v-btn :disabled="!eventdata.eventStreamKey" @click="startStreaming" depressed large class="primary rounded-xl"> <v-icon class="mr-2 red--text">icons8-record</v-icon>Start Streaming</v-btn>
                    </template>
                </div>
                <v-spacer />
                <div class="text-h7 white--text text-center">
                    {{ eventdata.eventTitle }}<br />
                    <v-chip label small class="font-weight-bold text-body-2"
                        >{{ MIX_formatDateTime(eventdata.eventStartDateTime, 'X', 'HH:mm:ss') }} -&nbsp;
                        <span v-if="$moment(eventdata.eventStartDateTime, 'X').format('X') < $moment().format('X')">Late by {{ startingIn }}</span>
                        <span v-else-if="$moment(eventdata.eventStartDateTime, 'X').format('X') > $moment().format('X') && streaming">Started</span>
                        <span v-else-if="$moment(eventdata.eventStartDateTime, 'X').format('X') > $moment().format('X') && !streaming" class="warning--text">Starting in {{ startingIn }}</span>
                    </v-chip>
                </div>
                <v-spacer />
                <div style="width: 20% !important" class="text-right d-flex align-center justify-end">
                    <div class="d-flex justify-center" v-if="streaming">
                        <span class="font-weight-bold white--text pr-3">{{ streamingTime }}</span>
                        <span class="red--text font-weight-light">Streaming</span>
                        <v-icon class="mx-2 red--text animate__animated animate__flash animate__slower animate__infinite">icons8-record</v-icon>
                    </div>
                    <div v-else>
                        <span class="grey--text font-weight-light">Offline</span>
                        <v-icon class="mx-2 grey--text">icons8-record</v-icon>
                    </div>
                    <div v-if="!showchat"><v-icon large color="primary" class="mr-3" @click="$emit('updatechat', true)">icons8-chat</v-icon></div>
                </div>
            </v-col>
        </v-row>
        <v-row class="mt-0" fillheight>
            <v-col cols="12" class="d-flex align-end justify-center">
                <v-card class="grey darken-4 elevation-0">
                    <video id="videoRef" ref="videoRef" muted class="" style="width: 100% !important; height: calc(100vh - 300px) !important"></video>
                    <v-overlay v-if="!streaming" absolute class="body-2">
                        <v-img max-height="200" :src="require('@/assets/arrow.png')" class="arrow" contain></v-img>
                        <div class="text-h3 font-weight-thin">To start streaming click the button above</div>
                        <p>
                            Alternatively if you prefer to use Desktop Streaming Software such as <a href="https://obsproject.com/" target="_blank" class="white--text font-weight-bold">Open Broadcast Software (OBS)</a><br />
                            <span @click="showStreamKey = !showStreamKey" class="white--text font-weight-bold">Click here</span> to reveal the stream key and host settings for Desktop Stream Sofware.
                        </p>

                        <p v-if="showStreamKey">
                            RTMP Host: <span class="grey--text">global-live.mux.com/app</span><br />
                            Stream Key: <span class="grey--text">{{ eventdata.eventStreamKey }}</span>
                        </p>
                    </v-overlay>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import RecordRTC from 'recordrtc'

var recorder
var wsRef

export default {
    name: 'StreamWeb',
    components: {},
    props: {
        showchat: Boolean,
        eventdata: Object
    },
    data: () => ({
        connected: false,
        cameraEnabled: false,
        streaming: false,
        startedStreaming: '',
        streamingTime: '',
        showStreamKey: false,
        //streamKey: "c301eac2-8f92-b335-ac3f-34da80d422d8",//"dd5e14a0-9341-47c6-07ca-685bb765243b", //"7cd4a748-3002-43a2-11d4-75a80385bfe3",
        downloadLocal: false,
        startingIn: null,
        videoDevices: [],
        audioInputDevices: [],
        audioOutputDevices: [],
        selectedAudioInputDevice: {},
        selectedAudioOutputDevice: {},
        selectedVideoDevice: {}
    }),
    computed: {},
    methods: {
        stopRecordingCallback() {
            this.$refs.videoRef.src = this.$refs.videoRef.srcObject = null
            recorder.camera.stop()
            recorder.destroy()
            wsRef.close()

            recorder = null
            wsRef = null
        },
        async stopStreaming() {
            this.streaming = false
            this.startedStreaming = ''
            recorder.stopRecording(this.stopRecordingCallback)
        },
        async startStreaming() {
            this.streaming = true
            this.startedStreaming = this.$moment().format('X')

            // wsRef = new WebSocket(`ws://oomph-stream-server-production-ftqyigyrea-ew.a.run.app/rtmp?key=${this.eventdata.eventStreamKey}`);
            // wsRef = new WebSocket(`ws://livestream-server.oomphwellness.org/rtmp?key=${this.eventdata.eventStreamKey}`);
            // wsRef = new WebSocket(`ws://46.101.19.113/rtmp?key=${this.eventdata.eventStreamKey}`);
            wsRef = new WebSocket(`wss://livestream-server.oomphwellness.org/rtmp?key=${this.eventdata.eventStreamKey}`)

            // ${process.env.VUE_APP_API_HOST}
            // oomph-stream-server-production-ftqyigyrea-ew.a.run.app/

            wsRef.addEventListener('open', function open() {
                this.connected = true
            })

            wsRef.addEventListener('close', () => {
                this.connected = false
                this.stopStreaming()
            })
            // var constraints = {
            //     exact: this.selectedVideoDevice.deviceId,
            // }
            let camera = await navigator.mediaDevices.getUserMedia({ audio: true, video: { height: this.$refs.videoRef.clientHeight } }) //}, deviceId: constraints} });
            this.$refs.videoRef.srcObject = camera
            recorder = RecordRTC(camera, {
                type: 'video',
                mimeType: 'video/webm',
                timeSlice: 1000, // pass this parameter
                ondataavailable: function (blob) {
                    wsRef.send(blob)
                }
            })

            recorder.startRecording()
            recorder.camera = camera
            this.$refs.videoRef.play()
        }
    },
    async mounted() {
        let t = this
        setInterval(() => {
            t.startingIn = t.MIX_formatDateTimeFromNow(t.eventdata.eventStartDateTime, 'X', true)
        }, 1000)

        setInterval(() => {
            if (this.startedStreaming !== '') {
                var startTime = this.startedStreaming
                var endTime = this.$moment().format('X')

                var duration = this.$moment(this.$moment(endTime, 'X').diff(this.$moment(startTime, 'X'))).format('mm:ss')

                this.streamingTime = duration
            }
        }, 1000)
    }
}
</script>

<style scoped>
.arrow {
    position: absolute;
    top: -280px;
    left: -35%;
    filter: contrast(0) brightness(0) invert(1);
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}
</style>
