<template>
    <v-container fluid fill-height :class="$vuetify.breakpoint.mobile ? 'd-flex flex-column pa-0 ma-0' : 'd-flex pa-0 ma-0'">
        <v-col cols="12" class="d-flex align-self-stretch justify-center pb-0">
            <v-card class="primary px-2 rounded-sm elevation-0 align-self-center rounded-xl" width="400">
                <v-card-title class="d-flex justify-center flex-column">
                    <div><v-img contain class="mt-2" :src="require('@/assets/logowhite.png')"></v-img></div>
                    <span class="headline py-4 white--text font-weight-bold">Live Stream App</span>
                    
                </v-card-title>
                <v-card-subtitle class="d-flex justify-center pt-2 white--text"> Enter your email and password below </v-card-subtitle>
                <v-card-text class="">
                    <v-form ref="form" lazy-validation>
                        <v-text-field background-color="white" outlined class="primary--text" v-model="form.userEmail" label="Email" type="email" append-icon="oomph-secured-letter" :rules="rules.emailRules" required></v-text-field>
                        <v-text-field
                        outlined
                        background-color="white"
                        class="primary--text"
                        v-model="form.password"
                        label="Password"
                        :append-icon="showPassword ? 'oomph-unavailable' : 'oomph-lock'"
                        :type="showPassword ? 'text' : 'password'"
                        @click:append="showPassword = !showPassword"
                        :rules="rules.passwordRules"
                        autocomplete="off"
                        hide-details="auto"
                        required
                        v-on:keyup.enter="validateForm()"
                        ></v-text-field>
                    </v-form>
                </v-card-text>
                <!-- <Alert border="left" :message="alertMessage" :value="value" :type="type" /> -->
                <v-card-actions class="pa-4 d-flex align-center justify-center flex-column">
                    <v-btn block x-large class="black white--text mb-4 elevation-0 rounded-lg" @click="validateForm()" :loading="loading">Login</v-btn>
                    <div class="white--text font-weight-bold caption">v{{ appVersion }}</div>
                </v-card-actions>
                <!-- <div class="body-1 float-left pl-4 font-weight-bold quaternary--text" style="cursor: pointer" @click="$router.push('/reset')">Reset Password</div> -->
            </v-card>
        </v-col>
    </v-container>
</template>

<script>
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { mapActions } from 'vuex';
import { version as appVersion } from '../../../package.json';

export default {
    data: () => ({
        appVersion: appVersion,
        alertMessage: '',
        form: {
            email: '',
            password: '',
        },
        emailRules: [(v) => !!v || 'Email is required', (v) => /.+@.+/.test(v) || 'Email must be valid'],
        loading: false,
        passwordRules: [(v) => !!v || 'Password is required'],
        showPassword: false,
        type: 'error',
        value: false,
        rules: []
    }),
    methods: {
        ...mapActions({
            ACT_FIREBASE_userAuth: 'firebase_auth_store/ACT_FIREBASE_userAuth',
        }),
        validateForm() {
            var t = this;

				t.rules = {
                    emailRules: [(v) => !!v || 'Email is required', (v) => /.+@.+/.test(v) || 'Email must be valid'],
					passwordRules: [(v) => !!v || 'Password is required'],
				};
				t.$nextTick(() => {
					if (t.$refs.form.validate()) {
						this.login()
					} else {
						this.MIX_alertBox({ color: "error", timeout: 2000, message: "Fill out all the fields correctly in order to proceed.", show: true });
					}
				});
        },
        login() {
            // if (this.$refs.form.validate()) {
                let t = this;
                t.loading = true;
                const auth = getAuth();
                signInWithEmailAndPassword(auth, t.form.userEmail, t.form.password)
                    .then(async (userCredential) => {            
                        const user = userCredential.user;
                        await t.MIX_FIREBASE_userAuth(user);
                    })
                    .then(() => {
                        t.$router.push('/schedule');
                        t.loading = false;
                    })
                    .catch((error) => {
                        // t.value = true;
                        // t.alertMessage = error.message;
                        t.loading = false;  
                        console.log(error.message);
                        this.MIX_alertBox({ color: "error", timeout: 2000, message: error.message, show: true });
					
                    });
            // }
        },
    },
};
</script>